.full-width {
  width: 100%;
}
.full-screen {
  height: 100vh;
  width: 100vw;
}
.w-auto {
  width: auto !important;
}
.w-600 {
  width: 600px;
}

.icon-1 {
  height: 14px;
  width: 14px;
}
.icon-2 {
  height: 24px;
  width: 24px;
}
.icon-3 {
  height: 36px;
  width: 36px;
}
