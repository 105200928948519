.pub-search-container {
  padding: $s-4 $s-6;
  max-width: 800px;
  @media #{$bp-mobile} {
    padding: $s-2;
  }
}

.pub-search {
  display: flex;
  align-items: center;
  background: $info-light;
  padding: $s-1 $s-3;
  .iui-input-container {
    margin: 0 !important;
  }
  .input-container {
    width: 100%;
    padding: 0;
  }
  gap: $s-2;
  @media #{$bp-mobile} {
    flex-direction: column;
    padding: $s-2 $s-3;
  }
}

.pub-search-result {
  padding: $s-3;
  display: flex;
  flex-direction: column;
  gap: $s-3;
}
