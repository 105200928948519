.tooltip-container {
  position: fixed;
  border-radius: 8px;
  background: #222;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 8px 16px;
  text-align: center;
  z-index: 1001;
  transition: 0.3s opacity;
  &:not(.visible) {
    visibility: hidden;
    opacity: 0;
  }
  &.widget-tooltip {
    max-width: 30%;
    @media #{$bp-mobile} {
      max-width: 90%;
    }
  }
}
.tooltip {
  position: relative;
  height: 100%;
  .arrow {
    position: absolute;
    border: 8px solid #222;
    z-index: 1001;
    &.top {
      top: calc(100% + 8px);
      left: calc(50% - 8px);
      border-color: #222 transparent transparent transparent;
    }
    &.bottom {
      bottom: calc(100% + 8px);
      left: calc(50% - 8px);
      border-color: transparent transparent #222 transparent;
    }
    &.left {
      top: calc(50% - 8px);
      left: calc(100% + 16px);
      border-color: transparent transparent transparent #222;
    }
    &.right {
      top: calc(50% - 8px);
      right: calc(100% + 16px);
      border-color: transparent #222 transparent transparent;
    }
  }
}
