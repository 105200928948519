.page-content {
  padding: 40px;
  @media #{$bp-mobile} {
    padding: 16px;
  }
}

.no-overflow {
  overflow: hidden;
}

.sticky {
  position: sticky;
  top: $s-3;
  height: fit-content;
}

.clickable {
  cursor: pointer;
}

.help-button {
  position: fixed;
  z-index: 100;
  bottom: 50px;
  right: 50px;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.5);
  @media #{$bp-mobile} {
    bottom: 20px;
    right: 20px;
  }
}
