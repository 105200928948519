.no-access {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: 64px;
  h2 {
    font-size: 32px;
  }
}
