.list {
  > :last-child {
    border-bottom: 1px solid $secondary;
  }
  .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $s-4;
    border-top: 1px solid $secondary;
    gap: $s-3;
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
      user-select: none;
    }
    &:hover:not(.disabled, .not_clickable) {
      cursor: pointer;
      background-color: rgba($secondary, 0.2);
    }
    &.active {
      background-color: rgba($secondary, 0.2);
    }
    .title {
      font-size: 14px;
      font-weight: bold;
    }
    p {
      font-size: 12px;
      line-height: 24px;
    }
  }
}
