.flex {
  display: flex;
  &.column {
    flex-direction: column;
  }
  &.center {
    align-items: center;
    justify-content: center;
  }
  &.wrap {
    flex-wrap: wrap;
  }
}

$aligns: (center, left, right);
$justifies: (
  center: center,
  left: left,
  right: right,
  around: space-around,
  between: space-between,
  evenly: space-evenly,
);

@each $key in $aligns {
  .text-#{$key} {
    text-align: $key !important;
  }
  .align-#{$key} {
    align-items: $key !important;
  }
}

@each $key, $value in $justifies {
  .justify-#{$key} {
    justify-content: $value !important;
  }
}
