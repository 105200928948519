@import "./base/variables";
@import "./base/responsive";
@import "./base/typography";
@import "./base/animations";
@import "./base/colors";
@import "./base/spacing";
@import "./base/size";
@import "./base/base";
@import "./base/flexBox";

@import "./elements/alert";
@import "./elements/button";
@import "./elements/dialog";
@import "./elements/infoBox";
@import "./elements/input";
@import "./elements/link";
@import "./elements/list";
@import "./elements/loader";
@import "./elements/menu";
@import "./elements/portal";
@import "./elements/table";
@import "./elements/tooltip";

@import "./components/files";
@import "./components/footer";
@import "./components/header";
@import "./components/section";
@import "./components/widget";

@import "./pages/noAccess";
@import "./pages/pubSearch";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}
