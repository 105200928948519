.iui-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $s-2;
  border-radius: 5px;
  background: none;
  border: none;
  color: $secondary;
  user-select: none;
  box-sizing: border-box;
  &.primary {
    background: $primary;
    color: white;
  }
  &.secondary {
    background: $secondary;
    color: white;
  }
  &.outlined {
    border: 2px solid $primary;
    color: $primary;
  }
  &:active:not(:disabled) {
    transform: translateY(2px);
  }
  &:hover:not(:disabled) {
    cursor: pointer;
    background: rgba($secondary, 0.25);
    &.outlined {
      background: rgba($primary, 0.25);
    }
    &.primary {
      background: $primary-dark;
    }
    &.secondary {
      background: darken($secondary, 10%);
    }
  }
  &:disabled {
    border-color: #00000026;
    color: #00000066;
    &.primary {
      background: #00000026;
    }
  }
  &.error {
    border-color: $error;
    color: $error;
    &:not(:disabled) {
      cursor: pointer;
      &:hover:not(.primary) {
        background: rgba($error, 0.25);
      }
    }
    &.primary {
      background: $error;
      color: white;
      &:hover {
        background: darken($error, 20%);
      }
    }
  }
  &.xs {
    border-width: 1px;
    font-size: 9px;
    font-weight: 600;
    padding: $s-1 $s-2;
  }
  &.s {
    border-width: 1px;
    font-size: 12px;
    font-weight: 600;
    padding: $s-2 $s-3;
  }
  &.m {
    font-size: 13px;
    font-weight: 700;
    line-height: 22px;
    padding: $s-2 $s-3;
  }
  &.l {
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    padding: $s-2 20px;
    svg {
      height: 18px;
      width: 18px;
    }
  }
  @media #{$bp-mobile} {
    width: 100%;
    &.xs {
      font-size: 10px;
      padding: $s-2;
    }
  }
}

.iui-icon-btn {
  width: auto;
  border-radius: 50%;
  padding: 10px;
  svg {
    height: 24px;
    width: 24px;
  }
}

.button-container {
  display: flex;
  justify-content: right;
  gap: $s-2;
  @media #{$bp-mobile} {
    flex-direction: column;
  }
  @media #{$bp-mobile-up} {
    margin-right: $s-5;
  }
}
.buttons-wrapper{
  .iui-btn{
    width: 170px;
  }
}
