.dashboard-widget-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 80px;
  margin-top: 100px;
  .widget {
    display: flex;
    align-items: flex-start;
    gap: $s-2;
    padding: $s-4;
    border: 1px solid $secondary;
    &:not(.disabled) {
      cursor: pointer;
      &:hover {
        background-color: rgba($secondary, 0.1);
      }
    }
    p {
      font-size: 12px;
      line-height: 26px;
      margin-top: $s-3;
    }
    &.disabled {
      opacity: 0.3;
      user-select: none;
    }
  }

  @media #{$bp-mobile} {
    display: flex;
    flex-direction: column;
    gap: $s-5;
    margin-top: 50px;
    .widget {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
}

.real-lands {
  background-color: #d1e597;
  &:hover{
    background-color: #d1e597!important;
  }
}
