.info-box {
  display: flex;
  flex-direction: column;
  gap: $s-2;
  background: $info;
  padding: $s-4;
  .title {
    font-weight: 700;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }
  &.s {
    .title {
      font-size: 11px;
    }
    p,
    li,
    a {
      font-size: 11px;
      line-height: 1.2;
    }
  }
  &.l {
    .title {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
  }
  &.danger {
    background: $error;
    color: white;
  }
  &.light {
    background: $info-light;
  }
  ul {
    padding-inline-start: 24px;
  }
  @media #{$bp-mobile} {
    p {
      font-size: 13px;
    }
  }
}

.statement {
  display: flex;
  gap: $s-3;
  align-items: flex-start !important;
  padding: $s-3;
  background: $info-light;
}
