.loader-container {
  position: relative;
  .loading-content {
    opacity: 0.3;
    pointer-events: none;
    user-select: none;
  }
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .spinner {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: inset -2px 0 0 2px $primary;
    animation: rotate 1.5s linear infinite;
  }
}
