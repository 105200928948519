.footer {
  display: flex;
  img {
    height: 180px;
  }
  .container-primary {
    box-sizing: border-box;
    height: 104px;
    background: $primary;
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 40px;
    p {
      font-size: 16px;
      line-height: 1.3;
    }
    h1 {
      font-size: 36px;
    }
  }
  .container-secondary {
    height: 76px;
    background: $secondary;
  }

  @media #{$bp-mobile} {
    max-width: 100vw;
    img {
      height: 90px;
    }
    .container-primary {
      height: 52px;
      padding: 0 16px 0 0;
      p {
        font-size: 10px;
      }
      h1 {
        font-size: 18px;
      }
    }
    .container-secondary {
      height: 38px;
    }
  }
}
