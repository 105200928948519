.iui-menu {
  position: absolute;
  padding: 4px 0;
  background: #eee;
  // box-shadow: $deep-shadow;
  overflow: auto;
  animation: fade-in 200ms forwards;
  overscroll-behavior: none;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &.s {
    min-width: 150px;
  }
  &.m {
    min-width: 250px;
  }
  &.l {
    min-width: 500px;
  }
}

.iui-menu-item {
  user-select: none;
  padding: 8px 16px;
  &.clickable:hover:not(:disabled):not(.disabled) {
    cursor: pointer;
    background-color: #eee;
    // background-color: var(--primary-o10);
  }
  &.with-devider:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  svg {
    opacity: 0.6;
    // color: var(--primary);
  }
}
.iui-menu-item-devider {
  margin: 0;
  &:last-child {
    display: none;
  }
}
