.mobile-hidden {
  @media #{$bp-mobile} {
    display: none !important;
  }
}

.mobile-only {
  @media #{$bp-mobile-up} {
    display: none !important;
  }
}

.portrait-hidden {
  @media #{$portrait} {
    display: none !important;
  }
}
