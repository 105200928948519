@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap");

* {
  font-family: "Montserrat", sans-serif;
}

h1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  @media #{$bp-mobile} {
    font-size: 14px;
  }
}

h2 {
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  margin: 0;
}

h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  margin: 0;
}
h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  margin: 0;
}

p,
a,
li {
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  margin: 0;
}

a {
  text-decoration: none;
}

.bold {
  font-weight: bold;
}

.new-line {
  white-space: pre-line !important;
}
.error-text {
  color: $error;
}
