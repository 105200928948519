.sidebar-link {
  color: $primary;
  padding: $s-3 $s-4;
  border-bottom: 1px solid;
  margin-right: $s-4;
  font-size: 14px;
  line-height: 30px;
  white-space: nowrap;
  min-width: 230px;
  cursor: pointer;

  &:hover:not(.disabled) {
    background: $info-light;
  }
  &.disabled {
    opacity: 0.3;
    cursor: default;
    pointer-events: none;
  }
  &.active {
    background: $info-light;
    font-weight: bold;
  }
}

.link {
  color: $link;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: darken($link, 20%);
  }
}
