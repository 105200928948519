table {
  border-spacing: 0;
  border-collapse: collapse;
  background-color: white;
}
.iui-table {
  overflow-x: auto;
  padding-top: 10px;
  &.table-5col {
    max-height: 325px;
  }
  //// Header ////

  th {
    p {
      font-weight: 700;
      font-size: 12px;
    }
    border-bottom: $table-border;
    &:last-child {
      .vertical-separator {
        display: none;
      }
    }
  }
  .vertical-separator {
    height: 14px;
    width: 2px;
    background-color: #00000030;
  }

  //// Body ////

  tbody.no-last-border tr:last-child td {
    border: none;
  }
  // Row
  tr {
    margin-bottom: 2px;
    &.clickable:hover,
    &.selected {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  // Cell
  td {
    height: 52px;
    padding: 7px 16px;
    font-size: 12px;
    box-sizing: border-box;
    border-bottom: $table-border;
  }
  .clickable-column:hover {
    background-color: var(--primary-o10) !important;
  }

  //// Footer ////

  .table-footer {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .pagination {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.8);
    @media screen and (max-width: 850px) {
      flex-direction: column;
      align-items: end;
    }
    select {
      color: rgba(0, 0, 0, 0.8);
      background: none;
      border: none;
      text-align: right;
      cursor: pointer;
      padding: 0 8px;
      user-select: none;
      margin-left: 8px;
      margin-right: 16px;
    }
    .offset-button {
      margin: 0 4px;
      padding: 10px;
      svg {
        height: 15px;
        width: 15px;
      }
    }
    .offset-input {
      width: 30px;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      background: none;
      text-align: center;
      padding: 5px 0;
    }
  }

  // @media #{$portrait} {
  //   tbody,
  //   tr {
  //     display: block;
  //   }
  //   tr {
  //     padding: 20px 0;
  //   }
  //   td {
  //     display: flex;
  //     align-items: center;
  //     height: unset !important;
  //     text-align: left;
  //   }
  //   thead {
  //     display: none;
  //   }
  //   tbody tr td {
  //     &:before {
  //       content: attr(data-th);
  //       font-weight: bold;
  //       text-align: left;
  //       padding-right: 10px;
  //     }
  //   }
  // }
}

// .iui-table-filter {
//   .iui-dialog-content {
//     padding: 0;
//     margin: 8px 0;
//   }
//   .table-filter-item {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 8px 16px;
//     .label {
//       width: 150px;
//     }
//     .field {
//       display: inline-flex;
//       margin: 0 8px;
//       width: 100%;
//     }
//   }
// }

// .dual-list-container {
//   .dual-list-content {
//     display: grid;
//     grid-template-columns: 1fr auto 1fr;
//   }
//   .list-box {
//     border: $table-border;
//     border-radius: 16px;
//     padding: 10px;
//     user-select: none;
//     & > .item {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       padding: 8px;
//       svg {
//         color: rgba(0, 0, 0, 0.6);
//         margin-left: 1rem;
//       }
//       &:hover {
//         background-color: var(--primary-o10);
//       }
//       &:active {
//         background-color: var(--primary-o25);
//       }
//     }
//   }
//   .exchange-icon {
//     display: flex;
//     align-items: center;
//     margin: 0 10px;
//     svg {
//       height: 20px;
//       width: 20px;
//     }
//   }
// }

// .edit-row {
//   background: #3d497714;
//   td {
//     padding: 0 3px !important;
//   }
//   input {
//     background: white;
//   }
// }

.recapitulation-table-container {
  background: rgba(164, 204, 61, 0.25);
  padding: $s-4;

  .recapitulation-table {
    width: 100%;
  }

  th,
  td {
    background: rgba(164, 204, 61, 0.25);
    border-bottom: 1px dashed #00000026;
    padding-top: $s-3;
    padding-left: $s-1;
    p {
      font-weight: bold;
    }
  }

  td p {
    color: rgba(0, 0, 0, 0.6);
  }

  tfoot {
    border-top: 3px dashed rgba(164, 204, 61);
  }
}
