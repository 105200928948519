.alert {
  padding: $s-2 $s-3;
  white-space: pre-line;
  display: flex;
  gap: $s-3;
  border-radius: 4px;
  p {
    font-size: 11px;
  }
  &.error {
    background: $error;
    color: white;
  }
  &.info {
    background: $warning;
  }
  &.success {
    background: $primary;
  }
}
.alert-icon {
  &.error {
    color: $error;
  }
  &.info {
    color: $warning;
  }
  &.success {
    color: $primary;
  }
}

.app-error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: $s-4;
  background: $info;
  padding: 80px $s-4;
  height: 100%;
}
