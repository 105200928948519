.page-section {
  h1 {
    padding: $s-3 $s-4;
    border-bottom: 2px solid $warning;
  }
  .content {
    max-width: 680px;
    padding: $s-4 $s-6;
    @media #{$bp-mobile} {
      padding: $s-4 0 $s-4 $s-4;
    }
  }
}

.page-sub-section {
  position: relative;
  background-color: $background-tinted;
  width: 100%;
  &.secondary {
    margin-top: $s-4;
    > .sub-section-title {
      border-color: $secondary;
      padding: $s-2 $s-1;
    }
  }
  &.tertiary {
    > .sub-section-title {
      border-color: $secondary;
      color: $primary-dark;
      .expandable {
        color: $secondary !important;
      }
    }
    // @media #{$bp-mobile} {
    //   margin-right: -24px;
    // }
    background-color: $background-darker;
  }
  .sub-section-title {
    border-top: 1px solid $primary;
    padding: $s-2 $s-3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      line-height: 24px;
    }
  }
  .sub-section-content {
    padding: $s-3 $s-4;
    @media #{$bp-mobile} {
      padding: $s-3 $s-2;
    }
  }
  .section-type {
    position: absolute;
    left: -40px;
    @media #{$bp-mobile} {
      left: -30px;
    }
  }
  .alert-container {
    display: flex;
    flex-direction: column;
    gap: $s-1;
    padding-top: $s-1;
    padding-bottom: $s-1;

    @media #{$bp-mobile-up} {
      padding-left: $s-2;
      padding-right: $s-6;
    }
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: $s-4;
}

.secondary-section-title {
  border-top: 1px solid $secondary;
  padding: $s-2 $s-3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $s-4;
  h2 {
    line-height: 24px;
  }
}
