.header {
  background: $primary;
  font-size: 13px;
  .logo {
    height: 52px;
    @media #{$bp-mobile} {
      height: 30px;
    }
  }
  .top-content {
    // @extend .flex, .justify-right, .align-center, .gap-4, .color-white;
    display: flex;
    justify-content: right;
    align-items: center;
    color: white;
    padding: $s-1 $s-4;
    gap: $s-5;
  }
  .bottom-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 24px 0px 0px 0px;
    padding: $s-3;
  }
  .user {
    display: flex;
    align-items: center;
    margin-left: $s-2;
    gap: $s-3;
    cursor: pointer;
  }
}

.nav-item {
  color: #000;
  font-size: 18px;
  padding: $s-2;
  user-select: none;
  cursor: pointer;
  &:hover,
  &.active {
    color: $primary;
  }
  @media #{$bp-mobile} {
    border-top: 1px solid rgba($primary, 0.5);
    padding: $s-3;
    &:last-child {
      border-bottom: 1px solid rgba($primary, 0.5);
    }
  }
}
